import * as React from 'react';
import { useState } from "react";

import { ICD10SearchResult } from "../generated/profile";

import Address from "./views/addressView/Address";
import Footer from "./Footer";
import Therapy from "./views/therapyView/Therapy";
import Results from "./Results";

type SearchProps = {
    address?: {
        lat: number,
        lon: number,
        zip?: string,
        city?: string,
        country?: string
    }
    therapy?: {
        therapyReasons: ICD10SearchResult[]
    }
}

export type StepProps = {
    setView: React.Dispatch<React.SetStateAction<string>>,
    searchProps: SearchProps,
    setSearchProps: React.Dispatch<React.SetStateAction<SearchProps>>
}

export default function StartPage() {
    const [searchProps, setSearchProps] = useState<SearchProps>({})
    const [view, setView] = useState('');

    switch(view) {
        case 'address':
            return <Address setView={setView} searchProps={searchProps} setSearchProps={setSearchProps} />
        case 'therapy':
            return <Therapy setView={setView} searchProps={searchProps} setSearchProps={setSearchProps} />
        default:
            return <div className="Content Home">
                <h1>Expertensuche</h1>
                <h3>In zwei Schritten zum passenden Experten.</h3>
                <img src="/icons/nibyou_big.svg" alt="Logo" />
                <button onClick={() => {setView('address')}}>Start</button>
                <Footer />
            </div>;
    }
}