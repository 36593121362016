import * as React from "react";

import Modal from "./Modal";
import { ReactNode } from "react";

type HelpModalProps = {
    setShowHelpModal: React.Dispatch<React.SetStateAction<Boolean>>,
    content: ReactNode
}

export default function HelpModal({setShowHelpModal, content} : HelpModalProps) {
    return <Modal modalId="ny-help-modal" setModalIsVisible={setShowHelpModal} closeOnClickOutside={true}>
        <img onClick={() => {setShowHelpModal(false)}} className="ny-modal-trigger" src="/icons/cross_red.svg" alt="Icon" />
        { content }
    </Modal>
}
