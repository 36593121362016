import * as React from "react";
import { useEffect, useState } from "react";

import { PractitionersSearchApi } from "../../../generated";
import { ICD10SearchResult } from "../../../generated/profile";

import Modal from "../../Modal";

type TherapyModalProps = {
    selectedDiseases: ICD10SearchResult[],
    setSelectedDiseases: React.Dispatch<React.SetStateAction<ICD10SearchResult[]>>
    setShowTherapyModal: React.Dispatch<React.SetStateAction<Boolean>>,
};

export default function TherapyModal({selectedDiseases, setSelectedDiseases, setShowTherapyModal}: TherapyModalProps) {
    const [searchResults, setSearchResults] = useState<ICD10SearchResult[]>([]);
    const [diseasesDtos, setDiseasesDtos] = useState<ICD10SearchResult[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (!target.closest('.searchResultItem')) {
            setSearchResults([]);
            setSearchTerm('');
        }
    };

    const handleSubmit = () => {
        diseasesDtos.forEach(diseaseDto => {
            if (!selectedDiseases.some(selectedDisease => selectedDisease.item.code === diseaseDto.item.code)) {
                setSelectedDiseases(prevSelectedDiseases => [...prevSelectedDiseases, diseaseDto]);
            }
        });

        setShowTherapyModal(false);
    };

    const SearchResultItem = ({result}: {result: ICD10SearchResult}) => {
        const handleClick = () => {
            if (!diseasesDtos.some((disease) => disease.item.code === result.item.code)) {
                setDiseasesDtos([...diseasesDtos, result]);
            }

            setSearchResults([]);
            setSearchTerm('');
        }

        return <div onClick={handleClick}>{result.item.code} | {result.item.name}</div>
    }

    const SelectedSearchResultItem = ({result}: {result: ICD10SearchResult}) => {
        const handleClick = (result: ICD10SearchResult) => {
            setDiseasesDtos(diseasesDtos.filter((item) => item.item.code !== result.item.code));
        }

        return <div className="modalReason">
            <div>{result.item.code} | {result.item.name}</div>
            <img src="/icons/trash.svg" alt="Icon" onClick={() => {handleClick(result)}} />
        </div>
    }    

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length < 2) {
            return;
        }

        PractitionersSearchApi.practitionerSearchControllerGetIcdReq(e.target.value, 10)
            .then(res => setSearchResults(res.data.results))
            .catch(error => console.log(error));
    }

    return <Modal modalId="ny-add-therapy-modal">
        <div className="header">
            <h2>Weitere Therapienründe auswählen</h2>
        </div>
        <div className="body">
            <div>Suchen:</div>
            <div id="modalTherapyReasons" className="modalTherapyReasons">
                <div className="search">
                    <input type="select" placeholder="Krankheit oder ICD-Code eingeben…" value={searchTerm} onChange={handleSearch} />
                    <div id="searchResults">
                        { searchResults && searchResults.map(result => {
                            return <SearchResultItem key={result.item.code} result={result} />
                        }) }
                    </div>
                </div>
                { diseasesDtos && diseasesDtos.map(diseasesDto => {
                    return <SelectedSearchResultItem key={diseasesDto.item.code} result={diseasesDto} />
                }) }
            </div>
        </div>
        <div className="footer">
            <div className="cancelWrapper">
                <img src="/icons/chevron-left-solid_red.svg" alt="Icon" />
                <button onClick={() => {setShowTherapyModal(false)}} className="cancel ny-modal-trigger">Abbrechen</button>
            </div>
            <button onClick={handleSubmit} className="continue ny-modal-trigger">Fertig</button>
        </div>
    </Modal>
}
