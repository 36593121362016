import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ICD10SearchResult } from "../../../generated/profile";

import { StepProps } from "../../Home";
import Header from "../../Header";
import Footer from "../../Footer";
import HelpModal from "../../HelpModal";
import TherapyModal from "./TherapyModal";

export default function Therapy({setView, searchProps, setSearchProps} : StepProps) {
    const [isValid, setIsValid] = useState(false);
    const [selectedDiseases, setSelectedDiseases] = useState<ICD10SearchResult[]>(searchProps?.therapy?.therapyReasons ?? []);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showTherapyModal, setShowTherapyModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsValid(selectedDiseases.length > 0)
    }, [selectedDiseases])

    const TherapyReason = ({therapyReason}: {therapyReason: ICD10SearchResult}) => {
        const handleClick = (result: ICD10SearchResult) => {
            setSelectedDiseases(selectedDiseases.filter((item) => item.item.code !== result.item.code));
        }

        return <div className="reason">
            <div>{therapyReason.item.name}</div>
            <img onClick={() => {handleClick(therapyReason)}} src="/icons/trash.svg" alt="Icon" />
        </div>
    }

    const getHelpModalContent = () => {
        return <p>
            Therapiegründe sind Krankheiten auf Grund
            derer Sie Therapie in Anspruch nehmen.
            Diesen finden Sie auch auf Ihrer Notwendig-
            keitsbescheinigung Ihres Hausarztes.
        </p>;
    }

    const handleCancel = () => {
        setSearchProps({...searchProps, therapy: {therapyReasons: selectedDiseases}});
        setView('address');
    }

    const handleSubmit = () => {
        setSearchProps({...searchProps, therapy: {therapyReasons: selectedDiseases}});
        setView('results');
        const red = `/results/${searchProps.address.lat},${searchProps.address.lon}/${selectedDiseases.reduce((acc, cv) => acc + cv.item.code + ",", "").slice(0, -1) }`
        navigate(red);
    }

    return <div className="Content Therapy">
        { showHelpModal && <HelpModal setShowHelpModal={setShowHelpModal} content={getHelpModalContent()} /> }
        { showTherapyModal && <TherapyModal selectedDiseases={selectedDiseases} setSelectedDiseases={setSelectedDiseases} setShowTherapyModal={setShowTherapyModal} /> }

        <Header />
        <form className="formWrapper">
            <div className="header">
                <h2>Schritt 2: Wähle deine Therapiegründe</h2>
                <button type="button" onClick={() => {setShowHelpModal(true)}} className="ny-modal-trigger">Hilfe</button>
            </div>
            <div className="body">
                <p>
                    Indem du auf den Button <img src="/icons/plus.svg" alt="Icon" /> drückst, 
                    kannst du einen oder mehrere Therapiegründe
                    Auswählen. Solltest du keinen passenden finden, klicke direkt auf
                    <span className="done">Fertig</span>
                </p>
                <div className="therapyReasonWrapper">
                    <div>
                        Grund wählen:
                    </div>
                    <div id="therapyReasons" className="therapyReasons">
                        {selectedDiseases && selectedDiseases.map(selectedDisease => {
                            return <TherapyReason key={selectedDisease.item.code} therapyReason={selectedDisease} />
                        })}
                        <img onClick={() => {setShowTherapyModal(true)}} className="add ny-modal-trigger" src="/icons/plus.svg" alt="Icon" />
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="cancelWrapper">
                    <img src="/icons/chevron-left-solid_blue.svg" alt="Icon" />
                    <button className="back" onClick={handleCancel}>Zurück</button>
                </div>
                <button className="continue" onClick={handleSubmit} disabled={!isValid}>Weiter</button>
            </div>
        </form>
        <Footer />
    </div>;
}