import * as React from "react";
import { useEffect, useState } from "react";

import { PractitionersSearchApi } from "../../../generated";

import { StepProps } from "../../Home";
import Header from "../../Header";
import Footer from "../../Footer";
import HelpModal from "../../HelpModal";

export default function Address({setView, searchProps, setSearchProps} : StepProps) {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
    const [zip, setZip] = useState<string>(searchProps?.address?.zip ?? '');
    const [city, setCity] = useState<string>(searchProps?.address?.city ?? '');
    const [country, setCountry] = useState<string>(searchProps?.address?.country ?? 'Deutschland');

    useEffect(() => {
        setIsValid(zip !== '' && city !== '' && country !== '')
    }, [zip, city, country])

    const getHelpModalContent = () => {
        return <p>
            Ihr Wohnort wird benötigt, um den den
            passenden Experten in Ihrer Nähe zu finden.
            Die Daten werden nicht dauerhaft gespeichert.
        </p>;
    }

    const handleCancel = () => {
        setSearchProps({...searchProps, address: { lat: 0, lon: 0, zip: zip, city: city, country: country }})
        setView('');
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const zipInput = document.querySelector<HTMLInputElement>('input[name=zip]');
        const cityInput = document.querySelector<HTMLInputElement>('input[name=city]');
        const countryInput = document.querySelector<HTMLInputElement>('select[name=country]');

        zipInput.classList.remove('invalid');
        cityInput.classList.remove('invalid');
        countryInput.classList.remove('invalid');

        const zipIsValid = /^\d{4,}$/.test(zip);

        if (!zipIsValid) {
            zipInput.classList.add('invalid');
        }

        if (!city) {
            cityInput.classList.add('invalid');
        }

        if (!country) {
            countryInput.classList.add('invalid');
        }

        if (!zipIsValid || !city || !country) {
            return;
        }

        try {
            const res = await PractitionersSearchApi.practitionerSearchControllerGetGeoLocation({address: `${zip} ${city} ${country}`});
            setView('therapy');
            setSearchProps({...searchProps, address: { lat: res.data.lat, lon: res.data.lon, zip: zip, city: city, country: country }});

        } catch (error) {
            zipInput?.classList.add('invalid');
            cityInput?.classList.add('invalid');
            countryInput?.classList.add('invalid');
            setIsValid(false);
        }
    }

    return <div className="Content Address">
        { showHelpModal && <HelpModal setShowHelpModal={setShowHelpModal} content={getHelpModalContent()} /> }

        <Header />
        <div className="formWrapper">
            <form onSubmit={handleSubmit} autoComplete="on">
                <div className="header">
                    <h2>Schritt 1: Gib deinen Wohnort ein</h2>
                    <button type="button" onClick={() => {setShowHelpModal(true)}} className="ny-modal-trigger">Hilfe</button>
                </div>
                <div className="body">
                    <div className="row">
                        <div className="left">PLZ / Stadt</div>
                        <div className="right">
                            <input type="text" name="zip" value={zip} onChange={e => setZip(e.target.value)} required={true} />
                            <input type="text" name="city" value={city} onChange={e => setCity(e.target.value)} required={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Land</div>
                        <div className="right">
                            <select name="country" onChange={e => setCountry(e.target.value)} required={true}>
                                <option value="Deutschland">Deutschland</option>
                                <option value="Österreich">Österreich</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="cancelWrapper">
                        <img src="/icons/chevron-left-solid_red.svg" alt="Icon" />
                        <button type="button" className="cancel" onClick={handleCancel}>Abbrechen</button>
                    </div>
                    <button disabled={!isValid} type="submit" className="continue">Weiter</button>
                </div>
            </form>
        </div>
        <Footer />
    </div>;
}