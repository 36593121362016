import * as React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from 'react';
import { Practice, PracticeNearYou } from '../generated/profile';
import { PractitionersSearchApi } from '../generated';
import { redirect, useParams } from 'react-router-dom';

type TMarker = {
  lat: number,
  lon: number,
  practice: PracticeNearYou
}

export default function Results() {
    const [markers, setMarkers] = useState<TMarker[]>([]);
    const { latlon, icd10 } = useParams();

    const address = {
      lat: Number(latlon?.split(",")[0]),
      lon: Number(latlon?.split(",")[1])
    }

    const diseases = icd10?.split(",");

    const query = {
      icd10: diseases.map(code => ({code})),
    };

    useEffect(() => {
      PractitionersSearchApi.practitionerSearchControllerFindNearPractices({
        limit: 50,
        location: {
          type: "Point",
          coordinates: [address.lat, address.lon]
        }, query: undefined, skip: 0, sphereKm: 1000
      }).then((practices => {
        setMarkers(practices.data.map(p => ({lat: p.dist.location.coordinates[0], lon: p.dist.location.coordinates[1], practice: p})));
      }))
    }, [])

    function scrollToPractice(id: string) {

    }

    function addr2str(address: {country?: string, zip?: string, city?: string, street?: string, houseNumber?: string, zipCode?: string}) {
      return (address.street && address.houseNumber) ? `${address.country}-${address.zip || address.zipCode}, ${address.city}; ${address.street} ${address.houseNumber}` : `${address.country}-${address.zip}, ${address.city}`;
    }

    function roundNearestHalf(num: number) {
      return Math.round(num * 2) / 2;
    }

    function starsForPractice(practice: PracticeNearYou) {
      const fullStars = Math.floor(practice.meanRating || 0);
      const halfStars = (roundNearestHalf(practice.meanRating || 0) - fullStars) === 0.5 ? 1 : 0;
      const emptyStars = 5 - fullStars - halfStars;
      const stars = [];

      for (let i = 0; i < fullStars; i++) {
        stars.push("solid")
      }

      for (let i = 0; i < halfStars; i++) {
        stars.push("half-stroke-solid")
      }

      for (let i = 0; i < emptyStars; i++) {
        stars.push("regular")
      }

      console.log(stars, fullStars, halfStars, emptyStars, "roundNearestHalf", roundNearestHalf(practice.meanRating || 0));

      return (
        <div className="star-test">
          { stars.map((star, index) => {
            return <img src={`/icons/star-${star}.svg`} alt="Star" key={`star-${practice._id}-${index}`} />
          }) }
        </div>
      );
    }

    return <div className="Content Results">
        <Header />
        <div className="contentWrapper">
            <div id="map" className="map">
                <MapContainer center={[address.lat, address.lon]} zoom={12} >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    <Marker position={[address.lat, address.lon]} title={"Sie sind hier"}>
                        <Popup>
                            <div onClick={() => {console.log('TEST')}}>
                              {  }
                            </div>
                        </Popup>
                    </Marker>
                    { markers.length && markers.map(marker => {
                      return (
                        <Marker position={[marker.lat, marker.lon]} key={`marker-${marker.practice._id}`}>
                          <Popup>
                            <div onClick={() => scrollToPractice(marker.practice._id)}>
                              { `${marker.practice.name}: ${addr2str(marker.practice.address)}` }
                            </div>
                          </Popup>
                        </Marker>
                      )
                    }) }
                </MapContainer>
            </div>
            <div className="detailWrapper">
                <button onClick={() => {redirect("/")}}>Neue Suche starten</button>
                <div className="detail">
                  { markers.length && markers.map(({practice}) => {
                    return (
                      <div className="practice">
                        <div className="header">
                          <h1>{ practice.name }</h1>
                          <h2>{ practice.admins && practice.admins[0] }</h2>
                        </div>
                        <div className="rating section">
                          <span>{ practice.meanRating.toFixed(2) }</span>
                          <div className="ratingStars">
                            { starsForPractice(practice) }
                          </div>
                          <span>({ practice.marketingInformation?.ratings?.length || 0 } Bewertungen)</span>
                        </div>
                        <div className="links section">
                          <div>
                            <img src="/icons/search-lense.svg" alt="Icon" />
                            <span>100% Übereinstimmung</span>
                          </div>
                          <div className="address">
                            <img src="/icons/location-solid.svg" alt="Icon" />
                            <span> { addr2str(practice.address) } </span>
                          </div>
                          <div className="website">
                            <img src="/icons/globe-solid.svg" alt="Icon" />
                            <span><a href={ practice.website }>{ practice.website }</a></span>
                          </div>
                          <div>
                            <img src="/icons/phone-solid.svg" alt="Icon" />
                            <span><a href={ practice.mobileNumber }></a></span>
                          </div>
                        </div>
                        <div className="description section">
                          <p>Praxisbeschreibung…</p>
                        </div>
                        <div className="therapyFocus section">
                          <h3>Therapieschwerpunkte</h3>
                          <div>
                            <img src="/icons/check-solid.svg" alt="Icon" />
                            <span>Krankheitsname</span>
                          </div>
                          <p>Text mit den weiteren Schwerpunkten</p>
                        </div>
                        <div className="photos section">
                          <h3>Fotos</h3>
                          <div className="photosWrapper">
                            <div className="photoWrapper" style={{
                              background:`url(/images/dummy_doctor.jpeg)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat"
                            }}></div>
                          </div>
                        </div>
                        <div className="qualifications sections">
                          <h3>Berufliche Ausbildung</h3>
                          <div>
                            <img src="/icons/check-solid.svg" alt="Icon" />
                            <span>Diätassistent</span>
                          </div>
                          <div>
                            <img src="/icons/check-solid.svg" alt="Icon" />
                            <span>Diplom-Ökotrophologe</span>
                          </div>
                        </div>
                      </div>
                    );
                  }) }
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}