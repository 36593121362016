import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";

type ModalProps = {
    children: ReactNode,
    modalId: string,
    closeOnClickOutside?: boolean,
    setModalIsVisible?: React.Dispatch<React.SetStateAction<Boolean>>
};

export default function Modal({children, modalId, closeOnClickOutside = false, setModalIsVisible = null}: ModalProps) {
    const modalRef = useRef<HTMLDivElement>(null);

    const scrollToElement = (element: Element) => {
        element.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setModalIsVisible(false);
        }
    }

    useEffect(() => {
        if (modalRef.current) {
            scrollToElement(modalRef.current);
        }

        if (setModalIsVisible && closeOnClickOutside) {
            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, []);

    return <div ref={modalRef} id={modalId ?? ''} className="ny-modal">
        <div className="ny-modal-content">
            {children}
        </div>
    </div>;
}